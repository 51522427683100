<template>
  <div class="about">
    <h1>This is an about page</h1>
    <img alt="Vue logo" src="@/../public/logo1.png">
    <img alt="Vue logo" :src="require('/public/logo1.png')">
    <div class="a"></div>
    <div style="width:100%">
      <a-tooltip
        class="setting-drawer-theme-color-colorBlock"
        v-for="(item, index) in colorList"
        :key="index"
      >
        <template v-slot:title>
          {{ item.key }}
        </template>
        <a-tag :color="item.color" @click="changeColor(item.color)"> </a-tag>
      </a-tooltip>
    </div>
    <Button type="primary" @click="test">123</Button>
    <a-progress :percent="40"></a-progress>
  </div>
</template>
<script>
import { test } from '@/services'
import { updateTheme, colorList } from '@/utils/theme'
import { Button } from 'ant-design-vue'

export default {
  name: 'About',
  components: {
    Button
  },
  data () {
    return {
      id: 0,
      fontSizeStep: 0,
      data: {},
      html: '',
      colorList: colorList
    }
  },
  methods: {
    changeColor (color) {
      updateTheme(color)
    },
    async test () {
      this.$message.success('123')
      try {
        const res = await test({
          name: 'admin',
          password: 'password'
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style  lang="less" scoped>
.setting-drawer-theme-color-colorBlock {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  float: left;
  cursor: pointer;
  margin-right: 8px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  color: #fff;
  font-weight: 700;

  i {
    font-size: 14px;
  }
}
.a{
  width: 100px;
  height: 100px;
  background-image: url("../../public/logo1.png");
}
</style>
